html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  background: #131313;
}

h1 {
  color: #ffffff;
}

.terms-privacy {
  background: #131313;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  margin: 16px;
}

.terms-privacy h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
}

.navbar {
  background: #242424;
  text-align: right;
  padding: 28px 16px;
  z-index: 9999999999999;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.navbar.hide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.home-link {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: #ffffff;
  text-align: left;
  position: absolute;
  margin-top: -12px;
  left: 0;
}

.home-link-logo {
  width: 48px;
  height: 48px;
  margin-right: 11px;
  vertical-align: middle;
}

.nav-link {
  font-size: 19px;
  margin-left: 40px;
  line-height: 24px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}

#home-page,
#promises-page,
#blog-page,
#article-page,
#about-page {
  text-align: center;
}

.main-title {
  margin-top: 96px;
  font-weight: 900;
  font-size: 48px;
  line-height: 103.69%;
  margin-bottom: 40px;
}

.app-store-button {
  background: #bb86fc;
  display: inline-block;
  border-radius: 100px;
  padding: 4px 8px;
}

.description {
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 96px;
}

.app-store-button img {
  display: block;
  height: 40px;
}

.top-wave {
  margin-bottom: -20px;
}

#comic {
  font-family: "Gaegu", cursive;
  background: #ffffff;
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.comic-main-title {
  font-weight: bold;
  font-size: 48px;
  line-height: 100.5%;
  letter-spacing: -0.08em;
  padding-top: 43px;
  margin-top: 0px;
}

#comic .intro-column {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

#comic .intro-column h3 {
  font-weight: bold;
  font-size: 36px;
  line-height: 105.4%;
  text-align: center;
  letter-spacing: -0.08em;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 17px;
}

#comic p {
  max-width: 474px;
  margin: 0 auto;
  font-size: 28px;
  line-height: 105.4%;
  text-align: center;
  letter-spacing: -0.08em;
  color: #383838;
}

#comic .comic-image {
  width: 100%;
  max-width: 550px;
}

#comic h3 {
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.08em;
  color: #000000;
}

#comic .read-our-comic-header {
  line-height: 36px;
  margin-top: 120px;
  padding-bottom: 21px;
  display: block;
}

#comic .down-arrow {
  margin-bottom: 120px;
}

#comic .derp-comic-pane,
#comic .dandy-comic-pane {
  display: inline-block;
  vertical-align: top;
  width: 47%;
  margin-top: 64px;
  margin-bottom: 94px;
}

#comic .derp-comic-pane p,
#comic .dandy-comic-pane p {
  font-family: Gaegu;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 105.4%;
  text-align: center;
  letter-spacing: -0.08em;
  color: #383838;
}

#comic .squiggle {
  margin-top: 170px;
}

/* MOBILE COMIC */

#comic .mobile-derp-comic-pane,
#comic .mobile-dandy-comic-pane {
  display: block;
  vertical-align: top;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 40px;
}

#comic .mobile-derp-comic-pane h3,
#comic .mobile-dandy-comic-pane h3 {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  margin: 0px;
  text-align: center;
  letter-spacing: -0.08em;
  color: #000000;
  margin-top: -10px;
  margin-bottom: 16px;
}

#comic .mobile-derp-comic-pane p,
#comic .mobile-dandy-comic-pane p {
  font-family: Gaegu;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 105.4%;
  text-align: center;
  letter-spacing: -0.08em;
  color: #383838;
}

.bottom-wave {
  position: absolute;
  min-height: 70px;
  z-index: 1;
  left: 0;
  margin-top: -25px;
}

.bottom-navbar {
  background: #242424;
  padding-top: 30px;
  padding-bottom: 26px;
  padding-right: 170px;
  position: relative;
  text-align: right;
}

.bottom-navbar div,
.bottom-navbar a {
  font-size: 16px;
  line-height: 24px;
  color: #aaaaaa;
}

.bottom-navbar div {
  position: absolute;
  left: 0;
  margin-left: 170px;
}

/* PROMISES PAGE */
#promises-page {
  text-align: center;
}

.promise-main-title,
.about-main-title {
  margin-bottom: 96px;
}

.blog-main-title {
  margin-bottom: 16px;
}

.promise {
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: 56px;
}

.promise-number {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #bb86fc;
  margin-bottom: 16px;
}

.promise-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 16px;
}

.promise-text {
  font-size: 20px;
  line-height: 23px;
  color: #aaaaaa;
}

.block-quote {
  background: #000000;
  max-width: 768px;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 96px;
  margin-bottom: 96px;
  padding: 48px 64px;
  padding-top: 30px;
}

.block-quote-icon {
  font-family: Copperplate;
  font-weight: bold;
  font-size: 96px;
  line-height: 96px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #bb86fc;
  height: 48px;
}

.block-quote-text {
  font-size: 20px;
  line-height: 23px;
  color: #aaaaaa;
}

.block-quote-author {
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.87);
}

/* ABOUT PAGE */

#about-page p {
  margin: 0 auto;
  max-width: 550px;
  font-size: 20px;
  line-height: 26px;
  color: #aaaaaa;
  text-align: left;
}

#about-page .about-page-intro {
  margin-bottom: 96px;
}

#about-page .milestones {
  position: relative;
  max-width: 550px;
  margin: 0 auto;
}

#about-page .derp-left {
  position: absolute;
  left: -70px;
}

#about-page .derp-right {
  position: absolute;
  right: -70px;
}

#about-page .derp-left .derp-title {
  margin-left: -30px;
}

#about-page .derp-right .derp-title {
  margin-right: -25px;
}

#about-page .derp-title {
  margin: 10px;
}

#about-page .derp-title {
  font-family: "Gaegu", cursive;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.08em;
  color: #ffffff;
}

#about-page .about-page-intro p {
  margin-bottom: 26px;
}

.milestone {
  max-width: 550px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 64px;
}

.milestone-image {
  width: 100%;
  max-width: 550px;
  border-radius: 8px;
}

.milestone-title {
  margin-top: 24px;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 8px;
}

.milestone-date {
  margin-top: 0px;
  font-size: 20px;
  line-height: 23px;
  color: #aaaaaa;
}

/* BLOG PAGE */

.blog-description {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #aaaaaa;
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: 96px;
}

.article {
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: 64px;
  text-decoration: none;
  display: block;
}

.article-title {
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.87);
}

.article-read-time {
  font-size: 20px;
  line-height: 23px;
  color: #aaaaaa;
  text-align: left;
}

/* ARTICLE PAGE */

#article-page .article-main-title {
  margin-bottom: 16px;
}

#article-page .article-description {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #aaaaaa;
  max-width: 640px;
  margin: 0 auto;
}

#article-page .article-read-time {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #aaaaaa;
  margin-bottom: 48px;
}

#article-page .article-image {
  max-width: 550px;
  margin: 0 auto;
}

#article-page .article-content {
  font-size: 20px;
  line-height: 30px;
  color: #aaaaaa;
  max-width: 550px;
  margin: 0 auto;
  text-align: left;
  margin-top: 48px;
  margin-bottom: 96px;
}

#article-page .article-description a,
#article-page .article-content a {
  color: #aaaaaa;
}
/* LARGE SCREEN */
@media only screen and (min-width: 1500px) {
  .bottom-wave {
    margin-top: -45px;
  }
  #home-page ~ .bottom-navbar {
    /* margin-top: -50px !important; */
    /* padding-top: 120px !important; */
  }
}
/* MEDIUM SCREEN */

@media only screen and (max-width: 1000px) and (min-width: 750px) {
  #comic .derp-comic-pane h3,
  #comic .dandy-comic-pane h3 {
    font-size: 25px;
    line-height: 25px;
  }
  #comic p {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }
  #comic .derp-comic-pane p,
  #comic .dandy-comic-pane p {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }
  #comic .squiggle {
    margin-top: 150px;
    height: 450px;
  }
  .navbar {
    padding-left: 16px;
    padding-right: 16px;
  }
  .bottom-navbar {
    text-align: left;
    padding-right: 0;
  }
  .bottom-navbar a.nav-link {
    position: relative;
    margin-left: 16px;
  }
  .bottom-navbar div.nav-link {
    display: block;
    position: relative;
    margin-left: 16px;
    margin-bottom: 24px;
  }
}

/* SMALL SIZE SCREEN */

@media only screen and (max-width: 750px) {
  .navbar {
    padding-left: 16px;
    padding-right: 16px;
  }
  #promises-page,
  #blog-page,
  #article-page,
  #about-page {
    padding-left: 16px;
    padding-right: 16px;
  }
  #promises-page .bottom-navbar,
  #blog-page .bottom-navbar,
  #article-page .bottom-navbar,
  #about-page .bottom-navbar {
    margin-left: -16px;
    margin-right: -16px;
  }
  .bottom-navbar {
    text-align: left;
    padding-right: 0;
    margin-top: 20px !important;
  }
  .bottom-navbar a.nav-link {
    position: relative;
    margin-left: 16px;
  }
  .bottom-navbar div.nav-link {
    display: block;
    position: relative;
    margin-left: 16px;
    margin-bottom: 24px;
  }
  .top-wave {
    min-height: 90px;
  }
  .bottom-wave {
    min-height: 120px;
    left: 0;
  }
  #comic {
    margin-top: 0px;
    padding-top: 0px;
  }
  #comic .comic-main-title {
    margin-bottom: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  #home-page .intro-column {
    width: 90%;
    margin: 0 auto;
    display: block;
    margin-top: 48px;
  }
  #comic .read-our-comic-header {
    margin-top: 65px;
    padding-bottom: 0px;
  }
  #comic .down-arrow {
    margin-bottom: 48px;
  }
  .derp-left,
  .derp-right {
    display: none;
  }
}

/* MOBILE MENU */
.mobile-menu {
  margin-top: 80px;
  background: #131313;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 32px;
}
.mobile-menu.show {
  z-index: 9999999999;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.mobile-menu.show a {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out 0.5s;
  -moz-transition: opacity 0.5s ease-in-out 0.5s;
  -ms-transition: opacity 0.5s ease-in-out 0.5s;
  -o-transition: opacity 0.5s ease-in-out 0.5s;
  transition: opacity 0.5s ease-in-out 0.5s;
}
.mobile-menu.hide a {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.mobile-menu.hide {
  opacity: 0;
  z-index: -1;
  -webkit-transition: opacity 1s ease-in-out 0.5s, z-index 1s ease-in-out 0.5s;
  -moz-transition: opacity 1s ease-in-out 0.5s, z-index 1s ease-in-out 0.5s;
  -ms-transition: opacity 1s ease-in-out 0.5s, z-index 1s ease-in-out 0.5s;
  -o-transition: opacity 1s ease-in-out 0.5s, z-index 1s ease-in-out 0.5s;
  transition: opacity 1s ease-in-out 0.5s, z-index 1s ease-in-out 0.5s;
}

.mobile-menu .nav-link {
  display: block;
  font-size: 48px;
  line-height: 103.69%;
  text-align: center;
  margin: 0;
  margin-top: 32px;
  color: #ffffff;
}
